// API
import BaseAPI from './base';

/**
 * Implement Core API
 */
export default class CoreAPI extends BaseAPI {
  constructor() {
    super('core');
  }

  /**
   * Get postal code info.
   */
  postalCodes(params) {
    return this.request.get(`${this.baseURI}/postal-codes`, { params });
  }
}
