<template>
  <Form @submit="submit">
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.first_name"
          label="Nombre *"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.last_name"
          label="Apellido Paterno *"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.mother_last_name"
          label="Apellido Materno *"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          counter="13"
          label="RFC *"
          maxlength="13"
          prepend-icon="mdi-pound"
          rules="required|min:12|max:13"
          :value="person.rfc"
          @input="person.rfc = $event ? $event.toUpperCase() : null"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.email"
          type="email"
          label="Correo electrónico *"
          prepend-icon="mdi-at"
          rules="required|email"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.birthday"
          type="date"
          label="Fecha de nacimiento *"
          prepend-icon="mdi-calendar"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.home_phone"
          counter="12"
          label="Teléfono de casa"
          maxlength="12"
          prepend-icon="mdi-phone"
          rules="min:8|max:12"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="person.mobile_phone"
          counter="12"
          label="Celular *"
          maxlength="12"
          prepend-icon="mdi-phone"
          rules="required|min:10|max:12"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextAreaField
          v-model="person.observations"
          label="Observaciones"
          rows="1"
          auto-grow
          prepend-icon="mdi-clipboard-text"
          rules=""
        />
      </v-col>

      <v-col
        v-if="showRole"
        cols="12"
        sm="6"
        lg="4"
      >
        <SelectField
          v-model="person.role"
          :items="roles"
          item-text="name"
          item-value="id"
          label="Rol *"
          prepend-icon="mdi-google-maps"
          rules="required"
        />
      </v-col>
    </v-row>

    <v-divider />

    <AddressForm :address="person.address" />

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
// Config
import config from '@/config';

// Components
import AddressForm from '@/components/address/Form.vue';

export default {
  name: 'PersonForm',

  props: {
    person: {
      type: Object,
      required: true,
    },

    showRole: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AddressForm,
  },

  data: () => ({
    roles: [
      { id: config.roles.DRIVER.key, name: config.roles.DRIVER.label },
      { id: config.roles.OPERATOR.key, name: config.roles.OPERATOR.label },
    ],
  }),

  methods: {
    submit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
