<template>
  <v-row>
    <v-col cols="12">
      <h3>Dirección</h3>
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <TextField
        v-model="address.street"
        label="Calle *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <TextField
        v-model="address.outer_number"
        label="Número Exterior *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <TextField
        v-model="address.inner_number"
        label="Número Interior"
        prepend-icon="mdi-google-maps"
        rules=""
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <TextField
        v-model="address.postal_code"
        counter="5"
        label="Código Postal *"
        maxlength="5"
        prepend-icon="mdi-google-maps"
        rules="required|min:5|max:5"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <SelectField
        v-model="address.suburb"
        :items="postalCodes"
        item-text="suburb"
        item-value="suburb"
        label="Colonia *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <SelectField
        v-model="address.municipality"
        :items="postalCodes"
        item-text="municipality"
        item-value="municipality"
        label="Municipio/Alcaldía *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <SelectField
        v-model="address.city"
        :items="postalCodes"
        item-text="city"
        item-value="city"
        label="Ciudad *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <SelectField
        v-model="address.state"
        :items="postalCodes"
        item-text="state"
        item-value="state"
        label="Estado *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>

    <v-col cols="12" sm="6" lg="4">
      <SelectField
        v-model="address.country"
        :items="countries"
        item-text="name"
        item-value="id"
        label="País *"
        prepend-icon="mdi-google-maps"
        rules="required"
      />
    </v-col>
  </v-row>
</template>

<script>
// API
import CoreAPI from '@/api/core';

export default {
  name: 'AddressForm',

  props: {
    address: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    countries: [{ id: 'MX', name: 'México' }],
    postalCodes: [],
  }),

  computed: {
    postalCode() {
      return this.address?.postal_code;
    },
  },

  watch: {
    postalCode: 'getPostalCodeInfo',
  },

  async created() {
    this.coreAPI = new CoreAPI();
    await this.getPostalCodeInfo();
  },

  methods: {
    async getPostalCodeInfo() {
      if (!this.postalCode || this.postalCode.length !== 5) return;

      this.$store.commit('showLoader');
      try {
        this.postalCodes = await this.coreAPI.postalCodes({
          postal_code: this.postalCode,
        });

        this.address.city = this.searchInPostalCodes('city', this.address.city);
        this.address.municipality = this.searchInPostalCodes('municipality', this.address.municipality);
        this.address.suburb = this.searchInPostalCodes('suburb', this.address.suburb);
        this.address.state = this.searchInPostalCodes('state', this.address.state);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    searchInPostalCodes(attribute, value) {
      const exists = this.postalCodes.some((postalCode) => postalCode[attribute] === value);

      return exists ? value : null;
    },
  },
};
</script>

<style lang="sass" scoped></style>
