/* eslint-disable import/prefer-default-export */
/**
 * Inital values for a person.
 */
export const initialPerson = () => ({
  address: {
    street: null,
    outer_number: null,
    inner_number: null,
    suburb: null,
    municipality: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  },
  birthday: null,
  customer_number: null,
  email: null,
  first_name: null,
  home_phone: null,
  last_name: null,
  mobile_phone: null,
  mother_last_name: null,
  observations: null,
  rfc: null,
  role: null,
});
